import React from 'react';
import "./Home.css";

const WhoWeAre = () => {
    return (
        <div className="who-we-are">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="about-img-wrap wow fadeInLeft">
                            <img className="shape-1" src="assets/images/home/ab-sahpe.png" alt="Shape" />
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="about-img image-1">
                                        <img className="image" src="assets/images/home/about-1.webp" width="291" height="496" alt="about-1" />
                                        <div className="shape-2"></div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="about-img-right">
                                        <div className="shape-2"></div>
                                        <div className="about-img image-2">
                                            <img className="image" src="assets/images/home/about-2.webp" width="291" height="531" alt="about-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="about-content wow fadeInRight">
                            <div className="section-title">
                                <h3 className="sub-title">Who we are</h3>
                                <h2 className="title">ConnectUs Infoway LLP has been a long-term trusted partner</h2>
                            </div>
                            <p>
                                We can help you channel your potential implementing your idea. We take care of all your needs, crafting specific and targeted solutions. A great client experience begins with a great team member experience.
                            </p>
                            <div className="about-list">
                                <ul>
                                    <li>
                                        <span className="about-icon"><i className="fas fa-check-circle"></i></span>
                                        <span className="about-text"> WEB DESIGN </span>
                                    </li>
                                    <li>
                                        <span className="about-icon"><i className="fas fa-check-circle"></i></span>
                                        <span className="about-text"> WEB DEVELOPMENT </span>
                                    </li>
                                    <li>
                                        <span className="about-icon"><i className="fas fa-check-circle"></i></span>
                                        <span className="about-text"> MOBILE APP DEVELOPMENT </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhoWeAre;
