import React from 'react';
import { NavLink } from 'react-router-dom';
import "./Home.css";

const ProjectStarted = () => {
    return (
        <div className="project-started">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="project-started-box wow fadeInUp">
                            <div className="project-started-shape-1"></div>
                            <div className="project-started-shape-2"></div>
                            <div className="project-started-shape-3"></div>
                            <div className="project-started-left">
                                <h2 className="project-started-title">LET'S GET YOUR PROJECT STARTED!</h2>
                            </div>
                            <div className="project-started-right">
                                <NavLink to="/contact" className="project-started-btn theme-btn">let’s get started</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectStarted;
