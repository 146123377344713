import React from 'react';
import ContactBox from './ContactBox';
import "./ContactUs.css";

const ContactDetails = () => {
    return (
        <div className="contact-us">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 wow fadeInLeft">
                        <ContactBox
                            img="assets/images/location_icon.svg"
                            title="VISIT OFFICE"
                            details="507/8, Business Edifice Near Hotel Samrat, B/H Bus Port, Canal Rd, Rajkot, Gujarat 360001"
                            value=""
                        />
                        <ContactBox
                            img="assets/images/call_icon.svg"
                            title="Call Anytime"
                            link="tel:+919898891896"
                            value="+91 989 889 1896"
                        />
                        <ContactBox
                            img="assets/images/mail_icon.svg"
                            title="SEND EMAIL"
                            link="mailto:hrconnectusinfoway@gmail.com"
                            value="hrconnectusinfoway@gmail.com"
                        />
                        <ContactBox
                            img="assets/images/skype_icon.svg"
                            title="Skype"
                            link="skype:connectussoftwaresolution?chat"
                            value="ConnectUs Infoway LLP."
                        />
                    </div>
                    <div className="col-lg-7 wow fadeInRight">
                        <div className="section-head">
                            <h2>Get In Touch</h2>
                        </div>
                        <div className="contact-form">
                            <form action="#" method="POST">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <input type="text" className="form-control" id="Name" placeholder="Name" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <input type="email" className="form-control" id="Email" placeholder="Email" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <input type="text" className="form-control" id="Phone" placeholder="Phone" />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <input type="text" className="form-control" id="Company" placeholder="Your Company" />
                                    </div>
                                    <div className="col-lg-12">
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Message"></textarea>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="send-message-btn">
                                            <input type="submit" value="Send" /> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 p-0">
                        <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.7035861477493!2d70.80071053261199!3d22.28921544017488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959ca0f62f38cc7%3A0xa1e1b5b09020408b!2sConnectUs%20Infoway%20LLP!5e0!3m2!1sen!2sin!4v1653294478243!5m2!1sen!2sin" title="map" className="contact-map" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactDetails;
