import React, { useEffect } from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./components/all.min.css";
import "./components/animate.min.css";
import "./index.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Services from "./routes/Services";
import Career from "./routes/Career";
import Contact from "./routes/Contact";
import WOW from 'wowjs';
import { Route, Routes } from "react-router-dom";

function App() {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/career" element={<Career />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </>
    );
}

export default App;