import React, { useState } from 'react';
import { useRef } from "react";
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    const [openProfile, setOpenProfile] = useState(false);

    const navRef = useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle(
            "responsive_nav"
        );
    };

    const [open, setOpen] = useState(false)
    const [color, setColor] = useState(false)
    const changeColor = () => {
        if (window.scrollY >= 95) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        <header className={color ? 'header header-bg' : 'header'}>
            <div className="container-fluid ps-5 pe-5 web-header">
                <div className="row align-items-center">
                    <div className="col-lg-3">
                        <div className="header-logo">
                            <NavLink to="/">
                                <img src="assets/images/logo.svg" width={291} height={75} alt="Logo" />
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <nav className="main-menu navbar navbar-expand-xl">
                            <div className="container-fluid p-0">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <i className="fas fa-bars"></i>
                                </button>
                                <div id="navbarSupportedContent" className="collapse navbar-collapse">
                                    <ul className="navbar-nav m-auto mb-2 mb-lg-0 navigation">
                                        <li className="nav-item">
                                            <NavLink to="/" className="nav-link">Home</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/about" className="nav-link">About Us</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/services" className="nav-link">SERVICES</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/career" className="nav-link">Career</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact" className="nav-link">Contact Us</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="col-lg-3">
                        <div className="header-contact d-flex">
                            <img src="assets/images/phone-icon.png" width={34} height={30} alt="Phone" />
                            <div className="header-content">
                                <p>Call Anytime</p>
                                <h5>
                                    <NavLink to="tel:+919898891896">+91 98988 91896</NavLink>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-header header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <NavLink to="/" className="navbar-brand header-logo">
                                <img src="assets/images/logo.svg" width={291} height={75} alt="Logo" />
                            </NavLink>
                            <div className="sidepanel" ref={navRef}>
                                <button
                                    className="closebtn"
                                    onClick={showNavbar}>
                                    <i className="fa-regular fa-circle-xmark"></i>
                                </button>
                                <nav className="navbar navbar-expand-xl">
                                    <div className="container-fluid">
                                        <div id="navbar-mobile" className="navbar-collapse collapse show">
                                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                                <li className="nav-item">
                                                    <NavLink to="/" className="nav-link">Home</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/about" className="nav-link">About Us</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/services" className="nav-link">SERVICES</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/career" className="nav-link">Career</NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/contact" className="nav-link">Contact Us</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <button
                                className="openbtn"
                                onClick={showNavbar}>
                                <i className="fas fa-bars"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
