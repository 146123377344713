import React from 'react';
import "./AboutUs.css";
import { useState } from "react";

const BestCompany = () => {

    const [toggleState, setToggleState] = useState(1);

        const toggleTab = (index) => {
            setToggleState(index);
        }

    return (
        <div className="best-company">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-xl-6">
                        <div className="best-company-box wow fadeInLeft">
                            <div className="section-head">
                                <h6>the best company</h6>
                                <h2>We combine strategy with marketing</h2>
                            </div>
                            <div className="best-company-tabs">
                                <ul className="best-company-tabs-menu">
                                    <li className={toggleState === 1 ? "tab active" : "tab"} onClick={() => toggleTab(1)}>
                                        <div className="best-company-title">
                                            <h4 className="title">Our Values</h4>
                                        </div>
                                    </li>
                                    <li className={toggleState === 2 ? "tab active" : "tab"} onClick={() => toggleTab(2)}>
                                        <div className="best-company-title">
                                            <h4 className="title">Our Goal</h4>
                                        </div>        
                                    </li>
                                    <li className={toggleState === 3 ? "tab active" : "tab"} onClick={() => toggleTab(3)}>
                                        <div className="best-company-title">
                                            <h4 className="title">Philosophy</h4>
                                        </div>    
                                    </li>
                                </ul>
                                <div className="best-company-tab-content">
                                    <div className={toggleState === 1 ? "best-company-tab-pane active-content" : "best-company-tab-pane"}>
                                        <div className="best-company-item">
                                            <h3 className="title">With over a decade of experience, we’ve established ourselves as one of the pioneering agencies in the region</h3>
                                            <p>We can help you channel your potential implementing your idea. We take care of all your needs, crafting specific and targeted solutions. A great client experience begins with a great team member experience.</p>
                                        </div>
                                    </div>
                                    <div className={toggleState === 2 ? "best-company-tab-pane active-content" : "best-company-tab-pane"}>
                                        <div className="best-company-item">
                                            <h3 className="title">Monthly Web Development Update Complexity Over HTTPS, And Push Notifications</h3>
                                            <p>A great client experience begins with a great team member experience. We can help you channel your potential implementing your idea. We take care of all your needs, crafting specific and targeted solutions.</p>
                                        </div>
                                    </div>
                                    <div className={toggleState === 3 ? "best-company-tab-pane active-content" : "best-company-tab-pane"}>
                                        <div className="best-company-item">
                                            <h3 className="title">Taking A Look At State Of Progressive Images And User Perception Weathers</h3>
                                            <p>We can help you channel your potential implementing your idea. We take care of all your needs, crafting specific and targeted solutions. A great client experience begins with a great team member experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-6">
                        <div className="marketing-image wow fadeInRight">
                            <div className="experience-wrap">
                                <div className="shape-1"></div>
                                <div className="experience">
                                    <div className="experience-icon">
                                        <img className="img-fluid" src="assets/images/about/laptop.png" width={47} height={47} alt="image" />
                                    </div>
                                    <div className="experience-text">
                                        <h3 className="title">10+ Years of Working Experience</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BestCompany;
