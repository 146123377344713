export const OurServicesData = [
    {
        icon: "flaticon-computer icon-image",
        heading: "Web Design",
        text: "At ConnectUs Infoway LLP, we shape your imagination and bring life to your thoughts, ideas, and dreams by creating adorable and innovative designs.",
        link: "/services"
    },
    {
        icon: "flaticon-responsive icon-image",
        heading: "Web Development",
        text: "ConnectUs Infoway LLP expert team converts your ideas into a significant product (a website or a platform or dashboard etc.) that matches and fulfills your expectations and needs.",
        link: "/services"
    },
    {
        icon: "flaticon-app-development icon-image",
        heading: "MOBILE APP DEVELOPMENT",
        text: "Mobile applications are among the best forms of performance support available today. Almost everything is operational with the help of a mobile application now.",
        link: "/services"
    }
]

export default OurServicesData;