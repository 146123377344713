import React from 'react';
import "./Home.css";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const ProjectReview = () => {
    const [counterState, setCounterState] = React.useState(false)
    return (
        <div className="project-review">
            <div className="container">
                <ScrollTrigger onEnter={() => setCounterState(true)} onExit={() => setCounterState(false)}>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-3">
                            <div className="project-count-box">
                                <div className="counter-one-icon wow zoomIn">
                                    <img className="img-fluid" src="assets/images/home/icon-checking.png" width="64" height="64" alt="Projects Completed" />
                                </div>
                                <h3 className="counter">
                                    {counterState && <CountUp start={0} end={153} duration={2.75}></CountUp>}
                                </h3>
                                <h4>Projects Completed</h4>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3">
                            <div className="project-count-box">
                                <div className="counter-one-icon wow zoomIn">
                                    <img className="img-fluid" src="assets/images/home/icon-recommend.png" width="64" height="64" alt="SATISFIED CLIENTS" />
                                </div>
                                <h3 className="counter">
                                    {counterState && <CountUp start={0} end={51} duration={2.75}></CountUp>}
                                </h3>
                                <h4>SATISFIED CLIENTS</h4>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3">
                            <div className="project-count-box">
                                <div className="counter-one-icon wow zoomIn">
                                    <img className="img-fluid" src="assets/images/home/icon-consulting.png" width="64" height="64" alt="EXPERT TEAMS" />
                                </div>
                                <h3 className="counter">
                                    {counterState && <CountUp start={0} end={19} duration={2.75}></CountUp>}
                                </h3>
                                <h4>EXPERT TEAMS</h4>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3">
                            <div className="project-count-box">
                                <div className="counter-one-icon wow zoomIn">
                                    <img className="img-fluid" src="assets/images/home/icon-increment.png" width="64" height="64" alt="REPEAT CUSTOMERS" />
                                </div>
                                <h3 className="counter">
                                    {counterState && <CountUp start={0} end={42} duration={2.75}></CountUp>}
                                </h3>
                                <h4>REPEAT CUSTOMERS</h4>
                            </div>
                        </div>
                    </div>
                </ScrollTrigger>
            </div>
            <div className="shape-1">
                <svg width="267.000000pt" height="151.000000pt" viewBox="0 0 267.000000 151.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,151.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M0 992 l0 -519 64 -27 c35 -15 147 -73 248 -128 100 -55 229 -123 284 -150 400 -192 687 -167 1033 89 328 244 629 712 775 1202 l15 51 -1210 0 -1209 0 0 -518z"></path></g>
                </svg>
            </div>
            <div className="shape-2">
                <svg width="231.000000pt" height="249.000000pt" viewBox="0 0 231.000000 249.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,249.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M2258 2368 c-63 -77 -124 -180 -226 -383 -123 -244 -215 -385 -318 -488 -161 -161 -284 -222 -677 -337 -267 -78 -329 -101 -457 -166 -318 -162 -465 -424 -487 -866 l-6 -128 1111 0 1112 0 0 1205 c0 687 -4 1205 -9 1205 -5 0 -24 -19 -43 -42z"></path></g>
                </svg>
            </div>
        </div>
    );
}

export default ProjectReview;
