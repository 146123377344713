import { Link, useLocation } from "react-router-dom";

const Breadcrumb = (props) => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);
    let breadcrumbPath = "";

    return (
        <div className="breadcrumb-section">
            <div className="container">
                <div className="breadcrumb-text wow zoomIn">
                    <h1>{props.title}</h1>
                </div>
                <ul className="breadcrumb-nav">
                    <li><Link to="/">Home</Link></li>

                    {pathnames.map((name, index) => {
                        breadcrumbPath += `/${name}`;
                        const isLast = index === pathnames.length - 1;
                        console.log(pathnames, breadcrumbPath);

                        return isLast ? (
                            <li key={breadcrumbPath}> {name}</li>
                        ) : (
                            <li key={breadcrumbPath}>
                                {" "}
                                 <Link to={breadcrumbPath}>{name}</Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Breadcrumb;