import React from "react";
import { Helmet } from 'react-helmet';
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import CareerBox from "../components/CareerBox";
import Footer from "../components/Footer";

const Career = () => {
    return (
        <div>
            <Helmet>
                <title>Career | ConnectUs Infoway LLP</title>
            </Helmet>
            <Navbar />
            <Breadcrumb title="CAREER" />
            <CareerBox />
            <Footer />
        </div>
    );
}

export default Career;
