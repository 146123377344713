import React from 'react';
import "./Home.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import HomeSliderData from './HomeSliderData';

const HomeSlider = () => {

    const HomeSliderItems = [
        {
            img: "assets/images/home/slider-1.webp",
            subtitle: "Welcome To",
            heading: "ConnectUs Infoway LLP",
            view: "about"
        },
        {
            img: "assets/images/home/slider-2.webp",
            heading: "Web Design",
            view: "services"
        },
        {
            img: "assets/images/home/slider-3.webp",
            heading: "Web Development",
            view: "services"
        },
        {
            img: "assets/images/home/slider-4.webp",
            heading: "Mobile Application",
            view: "services"
        },
    ]

    const options = {
        autoplay: true,
        loop: true,
        margin: 10,
        dots: true,
        nav: true,
        navText: [
            "<div class='prev-slide'><i class='fas fa-long-arrow-alt-left'></i> Prev</div>",
            "<div class='next-slide'>Next <i class='fas fa-long-arrow-alt-right'></i></div>"
        ],
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        mouseDrag: true,
        autoplayTimeout: 6000,
        smartSpeed: 4000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    return (
        <div className="home-slider">
            <OwlCarousel className="owl-carousel owl-theme" {...options}>
                {
                    HomeSliderItems.map(HomeSliderDetails => {
                        return (
                            <HomeSliderData HomeSliderDetails={HomeSliderDetails} key={HomeSliderDetails._key} />
                        )
                    })
                }
            </OwlCarousel>
        </div>
    );
}

export default HomeSlider;
