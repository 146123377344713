import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer-main">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="footer-link wow fadeInUp">
                            <NavLink to="/">
                                <img src="assets/images/logo.svg" width={272} height={70} alt="Logo" />
                            </NavLink>
                            <p>
                                <b>ConnectUs Infoway LLP</b> has been a long-term trusted partner for clients and is working with clients closely in order to act in the interest of clients for the long run. We always deliver what we promised.
                            </p>
                            <div className="footer-social-link">
                                <ul>
                                    <li>
                                        <NavLink to="https://www.facebook.com/ConnectUsInfowayLLP" target="_blank"><i className="fab fa-facebook-f"></i></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="https://twitter.com/connectusi53322" target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="https://in.linkedin.com/company/connectus-infoway-llp" target="_blank"><i className="fab fa-linkedin-in"></i></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="https://www.instagram.com/connectusinfowayllp" target="_blank"><i className="fab fa-instagram"></i></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="https://www.pinterest.com/connectusi/" target="_blank"><i className="fab fa-pinterest-p"></i></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-center">
                        <div className="footer-link wow fadeInUp">
                            <h6>Useful Links</h6>
                            <span className="animate-border border-black"></span>
                            <ul className="p-0 footer-menu">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/about">About</NavLink></li>
                                <li><NavLink to="/services">Services</NavLink></li>
                                <li><NavLink to="/career">Career</NavLink></li>
                                <li><NavLink to="/contact">Contact</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2 d-flex justify-content-center">
                        <div className="footer-link wow fadeInUp">
                            <h6>Our Services</h6>
                            <span className="animate-border border-black"></span>
                            <ul className="p-0 footer-menu">
                                <li><NavLink to="/services">Web Design</NavLink></li>
                                <li><NavLink to="/services">Web Development</NavLink></li>
                                <li><NavLink to="/services">Mobile Application</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="footer-link wow fadeInUp">
                            <h6>Contact</h6>
                            <span className="animate-border border-black"></span>
                            <div className="contact">
                                <div className="contact-icon">
                                    <img src="assets/images/location_icon.svg" width={30} height={30} alt="Location" />
                                </div>
                                <div className="contact-info">
                                    <h5>Our Location</h5>
                                    <p>
                                        507/8, Business Edifice <br />Near Hotel Samrat, B/H Bus Port, Canal Rd,<br /> Rajkot, Gujarat 360001
                                    </p>
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact-icon">
                                    <img src="assets/images/call_icon.svg" width={30} height={30} alt="Call" />
                                </div>
                                <div className="contact-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <NavLink to="tel:+919898891896">+91 98988 91896</NavLink>
                                    </p>
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact-icon">
                                    <img src="assets/images/mail_icon.svg" width={30} height={30} alt="Gmail" />
                                </div>
                                <div className="contact-info">
                                    <h5>Mail</h5>
                                    <p>
                                        <NavLink to="mailto:hrconnectusinfoway@gmail.com">hrconnectusinfoway@gmail.com</NavLink>
                                    </p>
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact-icon">
                                    <img src="assets/images/skype_icon.svg" width={30} height={30} alt="Skype" />
                                </div>
                                <div className="contact-info">
                                    <h5>Skype</h5>
                                    <p>
                                        <NavLink to="skype:connectussoftwaresolution?chat">ConnectUs Infoway LLP.</NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center wow fadeInUp">
                        <div className="copy-right">
                            <p>2023 © All Rights Reserved. <NavLink to="/">ConnectUs Infoway</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;