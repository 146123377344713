import React from "react";
import { Helmet } from 'react-helmet';
import Breadcrumb from "../components/Breadcrumb";
import Navbar from "../components/Navbar";
import WorkServices from "../components/WorkServices";
import Footer from "../components/Footer";

const Services = () => {
    return (
        <div>
            <Helmet>
                <title>Services | ConnectUs Infoway LLP</title>
            </Helmet>
            <Navbar />
            <Breadcrumb title="SERVICES" />
            <WorkServices />
            <Footer />
        </div>
    );
}

export default Services;
