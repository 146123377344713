import React from "react";
import { Helmet } from 'react-helmet';
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import ContactDetails from "../components/ContactDetails";
import Footer from "../components/Footer";

const Contact = () => {
    return (
        <div>
            <Helmet>
                <title>Contact | ConnectUs Infoway LLP</title>
            </Helmet>
            <Navbar />
            <Breadcrumb title="CONTACT" />
            <ContactDetails />
            <Footer />
        </div>
    );
}

export default Contact;
