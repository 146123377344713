import React from 'react';
import "./Home.css";
import LightGallery from 'lightgallery/react';

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-fullscreen.css";

import lgZoom from "lightgallery/plugins/zoom";
import lgFullscreen from "lightgallery/plugins/fullscreen";

const WorkShowcase = () => {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="work-showcase">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="section-head wow zoomIn">
                            <h6>RECENT PROJECTS</h6>
                            <h2>WORK SHOWCASE</h2>
                        </div>
                    </div>
                </div>
                <div className="portfolio-gallery wow fadeInUp">
                    <LightGallery className="row"
                        onInit={onInit}
                        speed={500}
                        plugins={[lgFullscreen, lgZoom]}
                    >
                        <a href="assets/images/services/emerald.webp">
                            <img className="img-fluid" src="assets/images/services/emerald-mockup.webp" width="640" height="360" alt="Emerald" />
                        </a>
                        <a href="assets/images/services/cafe-point.webp">
                            <img className="img-fluid" src="assets/images/services/cafepoint-mockup.webp" width="640" height="360" alt="Cafe Point" />
                        </a>
                        <a href="assets/images/services/girirajjewellers.webp">
                            <img className="img-fluid" src="assets/images/services/girirajjewellers-mockup.webp" width="640" height="360" alt="Giriraj Jewellers" />
                        </a>
                        <a href="assets/images/services/toolmaster-app.webp">
                            <img className="img-fluid" src="assets/images/services/toolmaster-app-mockup.webp" width="640" height="360" alt="Toolmaster" />
                        </a>
                        <a href="assets/images/services/shopmax.webp">
                            <img className="img-fluid" src="assets/images/services/shopmax-mockup.webp" width="640" height="360" alt="Shopmax" />
                        </a>
                        <a href="assets/images/services/hush.webp">
                            <img className="img-fluid" src="assets/images/services/hush-mockup.webp" width="640" height="360" alt="Hush" />
                        </a>
                    </LightGallery>
                </div>
            </div>
        </div>
    );
}

export default WorkShowcase;
