import React from 'react';

const CustomerCard = ({CusFedDetails}) => {
    const {description, cusname, designation, img} = CusFedDetails;
    return (
        <div className="item">
            <div className="testimonials-box">
                <div className="testimonials-box-content">
                    <p>{description}</p>
                    <h4>{cusname}</h4>
                    <h6>{designation}</h6>
                </div>
                <div className="testimonials-image">
                    <img className="img-fluid" src={img} alt="client" />
                </div>
            </div>
        </div>
    );
}

export default CustomerCard;
