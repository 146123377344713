import React from 'react';
import { NavLink } from 'react-router-dom';
import "./Home.css";

const Business = () => {
    return (
        <div className="business">
            <div className="counter-one-shape-1"></div>
            <div className="counter-one-shape-2"></div>
            <div className="counter-one-shape-3"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <div className="business-content wow zoomIn">
                            <h2>WE WORK WITH CLIENTS OF ALL SIZES CREATING BEAUTIFUL WEBSITES</h2>
                            <NavLink to="/contact" className="thm-btn">Lets Strat A Project</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Business;
