import React from "react";
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Breadcrumb from "../components/Breadcrumb";
import AboutUs from "../components/AboutUs";
import VideoSection from "../components/VideoSection";
import BestCompany from "../components/BestCompany";
import QualityWork from "../components/QualityWork";
import ProjectStarted from '../components/ProjectStarted';
import Footer from '../components/Footer';

const About = () => {

    return (
        <div>
            <Helmet>
                <title>About | ConnectUs Infoway LLP</title>
            </Helmet>
            <Navbar />
            <Breadcrumb title="ABOUT" />
            <AboutUs />
            <VideoSection />
            <BestCompany />
            <QualityWork />
            <ProjectStarted />
            <Footer />
        </div>
    );
}

export default About;
