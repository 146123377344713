import { Component } from 'react';
import { OurServicesData } from './OurServicesData';
import { NavLink } from 'react-router-dom';
import "./Home.css";

class OurServices extends Component {
    render() {
        return (
            <div className="our-services">
                <div className="services-head-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="section-head wow zoomIn">
                                    <h6>OUR SERVICES</h6>
                                    <h2>WHAT WE PROVIDE FOR YOU</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="services-section wow fadeInUp">
                        <div className="row">
                            {OurServicesData.map((item, index) => {
                                return (
                                    <div className="col-lg-4" key={index}>
                                        <div className="service-item service-item-1">
                                            <div className="service-icon">
                                                <span className={item.icon}></span>
                                            </div>
                                            <div className="service-content">
                                                <h3>{item.heading}</h3>
                                                <p>{item.text}</p>
                                                <NavLink to={item.link} className="link-btn">Learn More <i className="fas fa-arrow-right"></i></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OurServices;

