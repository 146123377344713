import React from 'react';
import { NavLink } from 'react-router-dom';

const HomeSliderData = ({ HomeSliderDetails }) => {
    const { img, subtitle, heading, view } = HomeSliderDetails;
    return (
        <div className="item">
            <img src={img} width={100} height={100} alt="slider" />
            <div className="item-caption">
                <div className="item-caption-inner">
                    <div className="item-caption-wrap">
                        <h6>{subtitle}</h6>
                        <h1>{heading}</h1>
                        <NavLink to={view} className="thm-btn">Discover More</NavLink>
                    </div>
                </div>
            </div>
            <div className="home-slider-shape-1"></div>
            <div className="home-slider-shape-2"></div>
            <div className="home-slider-shape-3"></div>
        </div>
    );
}

export default HomeSliderData;
