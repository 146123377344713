import React from 'react';
import "./Home.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CustomerCard from './CustomerCard';

const CustomerFeedbacks = () => {

    const CustomerFeedBack = [
        {
            description: "Five stars all the way! The service, the product, and the overall experience were outstanding. I will definitely be a repeat customer and will recommend them to everyone I know.",
            cusname: "Mick Deo",
            designation: "Director",
            img: "assets/images/home/author-1.png"
        },
        {
            description: "I had a fantastic experience with this business. The team was friendly, knowledgeable, and efficient. They made the entire process smooth and stress-free. I will definitely be using their services again in the future.",
            cusname: "Noel Gouldin",
            designation: "CEO",
            img: "assets/images/home/author-2.png"
        },
        {
            description: "I was blown away by the level of professionalism and expertise displayed by this company. They went above and beyond to ensure my satisfaction, and I can confidently say that they have earned a lifelong customer.",
            cusname: "Anissa Ross",
            designation: "Owner/Partner",
            img: "assets/images/home/author-3.png"
        },
        {
            description: "I’ve been a loyal customer for years, and I continue to be impressed with this company. Their team is dedicated to providing the best service possible, and it shows in every interaction. I highly recommend them to anyone in need of their services.",
            cusname: "Clay Washington",
            designation: "Founder",
            img: "assets/images/home/author-4.png"
        },
    ]

    const options = {
        autoplay:true,
        loop:true,
        margin:20,
        nav:true,
        navText: [
            "<i class='fas fa-long-arrow-alt-left'></i>",
            "<i class='fas fa-long-arrow-alt-right'></i>"
        ],
        dots: true,
        animateOut: 'slideOutLeft',
        animateIn: 'slideInRight',
        mouseDrag: true,
        autoplayTimeout: 4000,
        smartSpeed: 4000,
        responsiveClass: true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1200:{
                items:2
            }
        }
    };

    return (
        <div className="testimonial">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                        <div className="section-head wow fadeInLeft">
                            <h6>CUSTOMER FEEDBACKS</h6>
                            <h2>WHAT THEY’RE TALKING ABOUT COMPANY?</h2>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <div className="row wow fadeInUp">
                            <OwlCarousel className="owl-carousel owl-theme" {...options}>
                                {
                                    CustomerFeedBack.map(CusFedDetails => {
                                        return (
                                            <CustomerCard CusFedDetails={CusFedDetails} key={CusFedDetails._key} />
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerFeedbacks;
