import React from 'react';
import { NavLink } from 'react-router-dom';

function ContactBox(props) {
    return (
        <div className="contact-box d-flex">
            <img src={props.img} width={35} height={35} alt="Location Icon" />
            <div className="content-text">
                <p>{props.title}</p>
                <h5>{props.details}
                    <NavLink to={props.link}>{props.value}</NavLink>
                </h5>
            </div>
        </div>
    );
}

export default ContactBox;