import React from 'react';
import "./AboutUs.css";

const VideoSection = () => {
    return (
        <div className="video-section">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-end">
                    <div className="col-lg-5 col-md-8">
                        <div className="video-text wow fadeInRight">
                            <div className="section-head">
                                <h6>intro video</h6>
                                <h2>Let’s make an office tour from here.</h2>
                            </div>
                            <p>
                                Over the years, a wide range of developments &amp; innovations in the global IT arena have led to
                                many new IT-enabled devices and services being produced.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-wrap">
                <div className="video-bg">
                    <div className="video-link wow zoomIn">
                        <a href="javascript:;" className="popup_video"><i className="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoSection;
