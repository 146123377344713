import React from 'react';
import "./Career.css";
import { NavLink } from 'react-router-dom';

const CareerBox = () => {

    const CareerBoxData = [
        { 
            title: 'DOT NET DEVELOPER', 
            jobdescription: 'Job Description', 
            requiredqualification: 'Required Qualification',
            requiredqua: 'BCA / MCA / BSC.IT / MSC.IT / BE / ME / BTech / MTech / DIPLOMA', 
            requiredexperience: 'Required Experience', 
            requiredyear: '1 – 6 years', 
            link: 'https://forms.gle/yXmaGmB7M1dyn7UH6', 
            content: ['Participate in the entire software development lifecycle, from requirements gathering to deployment and maintenance.', 'Collaborate with cross-functional teams, including product managers, designers, and other developers, to define software specifications and design efficient solutions.', 'Develop and implement web applications, desktop applications, and/or web services using ASP.NET, ASP.NET Core, or other .NET technologies.', 'Write clean, scalable, and maintainable code using C# and the .NET framework.', 'Familiarity with web development technologies, such as HTML, CSS, JavaScript, and client-side frameworks (e.g., Angular, React, or Vue.js).', 'Knowledge of software testing principles and experience with unit testing frameworks.'] 
        },
        { 
            title: 'PHP Developer (Laravel, Code Igniter, WordPress)', 
            jobdescription: 'Job Description', 
            requiredqualification: 'Required Qualification', 
            requiredqua: 'BCA / MCA / BSC.IT / MSC.IT / BE / ME / BTech / MTech / DIPLOMA', 
            requiredexperience: 'Required Experience', 
            requiredyear: '1 – 6 years', 
            link: 'https://forms.gle/yXmaGmB7M1dyn7UH6', 
            content: ['Develop and maintain web applications using PHP frameworks such as Laravel and CodeIgniter.', 'Customize and enhance WordPress websites, including theme and plugin development.', 'Collaborate with designers, front-end developers, and other team members to create seamless user experiences.', 'Stay updated with the latest trends and advancements in PHP, Laravel, CodeIgniter, and WordPress development.', 'Proven experience as a PHP Developer with hands-on experience in Laravel and CodeIgniter frameworks.', 'Strong problem-solving skills and the ability to work on multiple projects simultaneously.', 'Proficiency in PHP, HTML, CSS, JavaScript, and MySQL.'] 
        },
        { 
            title: 'REACT NATIVE DEVELOPER', 
            jobdescription: 'Job Description', 
            requiredqualification: 'Required Qualification', 
            requiredqua: 'BCA / MCA / BSC.IT / MSC.IT / BE / ME / BTech / MTech / DIPLOMA', 
            requiredexperience: 'Required Experience', 
            requiredyear: '1 – 6 years', 
            link: 'https://forms.gle/yXmaGmB7M1dyn7UH6', 
            content: ['Collaborate with cross-functional teams to define, design, and ship new features for our mobile applications.', 'Build and maintain high-performance, reusable, and reliable React Native components and libraries.', 'Optimize applications for maximum performance and ensure seamless user experience.', 'Stay up-to-date with the latest industry trends, mobile technologies, and best practices in React Native development.', 'Proven experience in developing mobile applications using React Native.', 'Experience with state management libraries such as Redux or MobX.'] 
        },
    ];

    return (
        <div className="career">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="section-head wow zoomIn">
                            <h6>Career</h6>
                            <h2>Job Openings</h2>
                        </div>
                    </div>
                </div>
                <div className="row wow fadeInUp">
                    {CareerBoxData.map((item, index) => (
                        <div className="col-lg-6 mb-30" key={index}>
                            <div className="career-box">
                                <h4>{item.title}</h4>
                                <div className="job-text">
                                    <h5>{item.jobdescription}</h5>
                                    <ul>
                                        {item.content.map((item, itemIndex) => (
                                            <li key={itemIndex}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="job-text">
                                    <h5>{item.requiredqualification}</h5>
                                    <p>{item.requiredqua}</p>
                                </div>
                                <div className="job-text">
                                    <h5>{item.requiredexperience}</h5>
                                    <p>{item.requiredyear}</p>
                                </div>
                                <NavLink to={item.link} target="_blank" className="thm-btn-black">Apply Now</NavLink>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CareerBox;