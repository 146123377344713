import React from 'react';
import "./AboutUs.css";

const QualityWork = () => {
    const [counterState, setCounterState] = React.useState(false)
    const CareerBoxData = [
        { 
            title: 'FIT FOR PURPOSE',
            icon: "skill-per fit-purpose", 
            value: '93%', 
        },
        { 
            title: 'CONFORMANCE TO REQUIREMENTS', 
            icon: "skill-per conf-req", 
            value: '89%', 
        },
        { 
            title: 'COMPLETENESS', 
            icon: "skill-per completeness", 
            value: '99%', 
        },
        { 
            title: 'CORRECTNESS', 
            icon: "skill-per correctness", 
            value: '95%', 
        },
        { 
            title: 'DILIGENCE', 
            icon: "skill-per diligence", 
            value: '92%', 
        },
        { 
            title: 'PROFESSIONAL', 
            icon: "skill-per professional", 
            value: '100%', 
        },
        { 
            title: 'COMMUNICATION', 
            icon: "skill-per communication", 
            value: '97%', 
        },
    ];

    return (
        <div className="quality-work">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="skill-img text-right wow zoomIn">
                            <img src="assets/images/about/skill-img.webp" alt="image" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-6 ps-5 wow fadeInRight">
                        <div className="section-head">
                            <h6>Professional Skill’s</h6>
                            <h2>The quality of our work</h2>
                        </div>
                        {CareerBoxData.map((item, index) => (
                            <div className="skill-box" key={index}>
                                <span className="title">{item.title}</span>
                                <div className="skill-bar">
                                    <span className={item.icon}>
                                        <span className="tooltip">{item.value}</span>
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QualityWork;
