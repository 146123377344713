import React from 'react';
import "./Services.css";
import mixitup from 'mixitup';
import LightGallery from 'lightgallery/react';
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-fullscreen.css";
import { useEffect } from 'react';

import lgZoom from "lightgallery/plugins/zoom";
import lgFullscreen from "lightgallery/plugins/fullscreen";

const WorkServices = () => {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    useEffect(() => {
        mixitup(".items", {
            selectors: {
                target: ".item",
            },
            animation: {
                duration: 500
            }
        });
    }, []);
    return (
        <div className="work-showcase">
            <div className="container">
                <div className="row justify-content-center wow zoomIn">
                    <div className="col-lg-9 col-xl-8 text-center">
                        <div className="controls">
                            <button className="filter" data-filter="all">All</button>
                            <button className="filter" data-filter=".design">Web Design</button>
                            <button className="filter" data-filter=".website">Web Development</button>
                            <button className="filter" data-filter=".apps">Mobile App Development</button>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="items wow fadeInUp">
                            <LightGallery className="row"
                                onInit={onInit}
                                speed={500}
                                plugins={[lgFullscreen, lgZoom]}
                            >
                                <a href="assets/images/services/emerald.webp" className="item design website">
                                    <img className="img-fluid" src="assets/images/services/emerald-mockup.webp" width="640" height="360" alt="Emerald" />
                                </a>
                                <a href="assets/images/services/cafe-point.webp" className="item website design">
                                    <img className="img-fluid" src="assets/images/services/cafepoint-mockup.webp" width="640" height="360" alt="Cafe Point" />
                                </a>
                                <a href="assets/images/services/girirajjewellers.webp" className="item design website">
                                    <img className="img-fluid" src="assets/images/services/girirajjewellers-mockup.webp" width="640" height="360" alt="Giriraj Jewellers" />
                                </a>
                                <a href="assets/images/services/toolmaster-app.webp" className="item apps">
                                    <img className="img-fluid" src="assets/images/services/toolmaster-app-mockup.webp" width="640" height="360" alt="Toolmaster" />
                                </a>
                                <a href="assets/images/services/starfish.webp" className="item design">
                                    <img className="img-fluid"src="assets/images/services/starfish-mockup.webp" width="640" height="360" alt="Starfish" />
                                </a>
                                <a href="assets/images/services/shopmax.webp" className="item apps">
                                    <img className="img-fluid" src="assets/images/services/shopmax-mockup.webp" width="640" height="360" alt="Shopmax" />
                                </a>
                                <a href="assets/images/services/hush.webp" className="item design">
                                    <img className="img-fluid" src="assets/images/services/hush-mockup.webp" width="640" height="360" alt="Hush" />
                                </a>
                                <a href="assets/images/services/biznest.webp" className="item apps">
                                    <img className="img-fluid" src="assets/images/services/biznest-mockup.webp" width="640" height="360" alt="Biznest" />
                                </a>
                                <a href="assets/images/services/toolmaster.webp" className="item website">
                                    <img className="img-fluid" src="assets/images/services/toolmaster-mockup.webp" width="640" height="360" alt="Toolmaster" />
                                </a>
                                <a href="assets/images/services/bmt.webp" className="item design website">
                                    <img className="img-fluid" src="assets/images/services/bmt-mockup.webp" width="640" height="360" alt="British Motorsport Trust" />
                                </a>
                                <a href="assets/images/services/skh.webp" className="item apps">
                                    <img className="img-fluid" src="assets/images/services/skh-mockup.webp" width="640" height="360" alt="SKH" />
                                </a>
                            </LightGallery>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WorkServices;
