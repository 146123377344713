import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import HomeSlider from '../components/HomeSlider';
import OurServices from '../components/OurServices';
import WhoWeAre from '../components/WhoWeAre';
import WorkShowcase from '../components/WorkShowcase';
import ProjectReview from '../components/ProjectReview';
import CustomerFeedbacks from '../components/CustomerFeedbacks';
import Business from '../components/Business';
import ProjectStarted from '../components/ProjectStarted';
import Footer from '../components/Footer';

function Home() {

    return (
        <>
            <Helmet>
                <title>ConnectUs Infoway LLP</title>
            </Helmet>
            <Navbar />
            <HomeSlider />
            <OurServices />
            <WhoWeAre />
            <WorkShowcase />
            <ProjectReview />
            <CustomerFeedbacks />
            <Business />
            <ProjectStarted />
            <Footer />
        </>
    );
}

export default Home;
