import React from 'react';
import "./AboutUs.css";

const AboutUs = () => {
    return (
        <div className="we-are">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="image-column wow fadeInLeft">
                            <div className="inner">
                                <div className="image-block">
                                    <img src="assets/images/about/about-us-1.webp" alt="about-us-1" className="img-fluid" />
                                </div>
                                <div className="image-block">
                                    <img src="assets/images/about/about-us-2.webp" alt="about-us-2" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="we-are-content wow fadeInRight">
                            <div className="section-head">
                                <h6>About Us</h6>
                                <h2>WE ARE CONNECTUS</h2>
                                <h5>ConnectUs Infoway LLP</h5>
                                <p>
                                    has been a long-term trusted partner for clients and is working with clients closely in order to act in the interest of clients for the long run. We always deliver what we promised.
                                </p>
                            </div>
                            <div className="we-are-point">
                                <ul>
                                    <li>
                                        <div className="about-icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="about-text">
                                            <h3 className="title">Why did you choose us?</h3>
                                            <p>
                                                I really did like your work. All the work was completed in a proper time. The design was realized precisely in way we sketched it. I didn't find any disadvantage or flow in work.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="about-icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="about-text">
                                            <h3 className="title">Are you satisfied with the result?</h3>
                                            <p>
                                                Buildout was made perfectly without any flows. All the colors and smallest details create a cozy and warm atmosphere.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="about-icon">
                                            <i className="fas fa-check"></i>
                                        </div>
                                        <div className="about-text">
                                            <h3 className="title">Do you recommend us to your friends?</h3>
                                            <p>
                                                For sure. We had an easy experience with this company. Didn't have any troubles during their work or extra payments. We have got an excellent result
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
